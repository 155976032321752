import { FetchClient } from "../utils/fetchClient";

export const getAllFaqs = async () => {
    return FetchClient(`/ccai/faqs`).GET({})
}

export const createFaq = async (body) => {
    return FetchClient(`/ccai/faqs`).POST({ payload: JSON.stringify(body) })
}

export const updateFaqById = async (id, body) => {
    return FetchClient(`/ccai/faqs?id=${id}`).PUT({ payload: JSON.stringify(body) })
}

export const getFaqsByFranchiseId = async (franchiseId) => {
    return FetchClient(`/ccai/faqs?franchise_id=${franchiseId}`).GET({})
}

export const getFaqsByBrandId = async (brandId) => {
    return FetchClient(`/ccai/faqs?brand_id=${brandId}`).GET({})
}

export async function getFaqs(brandId = 0, franchiseId = 0, type = "", key, selectedSearchKeywords = [], page, size) {
    const keywordsQuery = selectedSearchKeywords?.join(",")
    return FetchClient(`/ccai/faqs?brand_id=${brandId}&franchise_id=${franchiseId}&fetchType=${type}&key=${key}&page=${page}&size=${size}&keyword=${keywordsQuery}`).GET({})
}

export async function getFaqsForAllFranchises(franchiseIds = [], key, selectedSearchKeywords = [], page, size){
    const keywordsQuery = selectedSearchKeywords?.join(",")
    const franchiseIdsQuery = franchiseIds?.join(",")
    return FetchClient(`/ccai/faqs/allFranchises?franchise_ids=${franchiseIdsQuery}&key=${key}&page=${page}&size=${size}&keyword=${keywordsQuery}`).GET({})
}

export const deleteFaqById = async (id) => {
    return FetchClient(`/ccai/faqs?id=${id}`).DELETE({})
}

export const getFaqsByKeyWords = async (body) => {
    if (body.keys !== null && body.keys !== "") {
        return FetchClient(`/ccai/faqs/by-keys`).POST({ payload: JSON.stringify(body) })
    }
}