import React, { useContext, useEffect, useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import "../styles/scoreDashboardStyles.css"
import { getAllActiveUsers } from '../../../../services/userService';
import { agentTypes, sortAgentsAlphabetically } from '../utils';
import { Autocomplete, Avatar, Box, IconButton, Tooltip } from '@mui/material';
import { CustomTextField } from '../../jobs/components/customComponents';
import { renderAgentOption, renderStateOption } from './agentLogsRenderInputs';
import { getAgentLogs, getAgentStatusCodes, getFirebaseJWTToken } from '../../../../services/agentScoreService';
import ReactDatePicker from 'react-datepicker';
import EventIcon from '@mui/icons-material/Event';
import { getDatabase, ref, off, onChildAdded, query, orderByChild, equalTo } from "firebase/database";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import moment from 'moment';
import { UserContext } from '../../../../contexts/User';
import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';
import { Link } from 'react-router-dom';
import AgentLogsListView from './agentLogsListView';
import AgentLogsTileView from './agentLogsTileView';

const LOGS_VIEW_OPTIONS = {
    TILE: "tile",
    LIST: "list"
}

let refNode;
const realTimeDbNode = "Logs"
let timeOuts = []

export default function AgentLogs() {

    const [agents, setAgents] = useState([])
    const [agentStatus, setAgentStatus] = useState([])
    const [logs, setLogs] = useState([])
    const [agent, setAgent] = useState(null)
    const [date, setDate] = useState(new Date())
    const [page, setPage] = useState(1)
    const [agentState, setAgentState] = useState([])
    const [isAgentsLoading, setIsAgentsLoading] = useState(true)
    const [isAgentLogsLoading, setIsAgentLogsLoading] = useState(false)
    const [isAgentStatusLoading, setIsAgentStatusLoading] = useState(true)
    const [totalCount, setTotalCount] = useState(1)
    const [isLogsFromRealTimeDb, setIsLogsFromRealTimeDb] = useState(true)
    const [totalLogs, setTotalLogs] = useState(0)
    const [logsViewOption, setLogsViewOption] = useState(LOGS_VIEW_OPTIONS.TILE)
    const { me } = useContext(UserContext)

    useEffect(() => {
        async function fetchData() {
            if (me.user_id) {
                let token = await getFirebaseJWTToken(me.user_id)
                const auth = getAuth();
                signInWithCustomToken(auth, token)
                    .then((userCredential) => {
                        // Signed in
                        const database = getDatabase();
                        refNode = ref(database, realTimeDbNode)
                        handleGetAgents()
                        handleGetAgentStatus()
                    })
                    .catch((error) => {
                        setAgent(null)
                        setAgents([])
                        setAgentStatus([])
                    });
            }
        }
        fetchData()
    }, [me.user_id])

    useEffect(() => {
        setLogs([])
        setTotalLogs(0)
        if (moment(new Date(date)).format("YYYY MM DD") === moment(new Date()).format("YYYY MM DD") && agent) {
            handleGetDataFromRealTimeDb(agent)
        } else {
            const pageSize = 10
            handleGetDataFromSql(date, agent, page, pageSize)
        }
    }, [logsViewOption])

    useEffect(() => {
        if (logs.length !== 0) {
            if (agentState.length !== 0) {
                logs.forEach((log) => {
                    if (agentState.includes(log.Status)) {
                        setTotalLogs(prevCount => prevCount + 1)
                    }
                })
            } else {
                setTotalLogs(logs.length)
            }
        }
    }, [agentState])

    useEffect(() => {
        if (isLogsFromRealTimeDb && logs.length !== 0) {
            if (agentState.length === 0) {
                setTotalLogs(prevCount => prevCount + 1)
            } else {
                if (agentState.includes(logs[0].Status)) {
                    setTotalLogs(prevCount => prevCount + 1)
                }
            }
        }
    }, [logs])

    useEffect(() => {
        if (page > 0 && (moment(new Date(date)).format("YYYY MM DD") !== moment(new Date()).format("YYYY MM DD"))) {
            handleGetDataFromSql(date, agent, page, 10)
        }
    }, [page])

    useEffect(() => {
        if ((moment(new Date(date)).format("YYYY MM DD") !== moment(new Date()).format("YYYY MM DD"))) {
            setPage(1)
            setLogs([])
            handleGetDataFromSql(date, agent, page, 10)
        }
    }, [agentState])

    const handleGetAgents = async () => {
        try {
            setIsAgentsLoading(true)
            let data = await getAllActiveUsers(me?.level <=60 ? agentTypes?.ALL_ACTIVE_AGENTS : agentTypes?.ALL_ACTIVE_AGENTSINCUDESUPERVISORS )
            setAgents(sortAgents(data?.users))
            if (data.users.length !== 0) {
                setAgent(sortAgents(data.users)[0])
                handleGetDataFromRealTimeDb(sortAgents(data.users)[0])
            }
        } catch (error) {
            setAgent(null)
            setAgents([])
        } finally {
            setIsAgentsLoading(false)
        }
    }

    const handleGetAgentStatus = async () => {
        try {
            setIsAgentStatusLoading(true)
            let data = await getAgentStatusCodes()
            data?.status?.sort((a, b) => a < b ? -1 : 1)
            setAgentStatus(data?.status)
        } catch (error) {
        } finally {
            setIsAgentStatusLoading(false)
        }
    }

    const sortAgents = (agents) => {
        let agentsWithAgentId = []
        let agentsWithOutAgentId = []
        let allAgents = []
        agents.forEach((agent) => {
            if (agent.eight_by_eight_agent_id !== "") {
                agentsWithAgentId.push(agent)
            } else {
                agentsWithOutAgentId.push(agent)
            }
        })
        allAgents = sortAgentsAlphabetically(agentsWithAgentId).concat(sortAgentsAlphabetically(agentsWithOutAgentId))
        return allAgents
    }

    const handleDateChange = (value) => {
        const pageSize = 10
        setTotalLogs(0)
        setDate(new Date(value))
        setPage(1)
        setTotalCount(1)
        setLogs([])
        if (moment(new Date(value)).format("YYYY MM DD") === moment(new Date()).format("YYYY MM DD")) {
            handleGetDataFromRealTimeDb(agent)
        } else {
            off(refNode)
            if (timeOuts.length !== 0) {
                timeOuts.forEach((timeoutId) => {
                    clearTimeout(timeoutId)
                })
            }
            handleGetDataFromSql(value, agent, page, pageSize)
        }
    }

    const handleAgentChange = (agent) => {
        const pageSize = 10
        setTotalLogs(0)
        setPage(1)
        setTotalCount(1)
        setLogs([])
        setAgent(agent)
        if (moment(new Date(date)).format("YYYY MM DD") === moment(new Date()).format("YYYY MM DD")) {
            handleGetDataFromRealTimeDb(agent)
        } else {
            off(refNode)
            handleGetDataFromSql(date, agent, page, pageSize)
        }
    }

    const handleGetDataFromRealTimeDb = (selectedAgent) => {
        setIsLogsFromRealTimeDb(true)
        off(refNode)
        if (timeOuts.length !== 0) {
            timeOuts.forEach((timeoutId) => {
                clearTimeout(timeoutId)
            })
        }
        const agentIdQuery = query(refNode, orderByChild("AgentId"), equalTo(selectedAgent.eight_by_eight_agent_id));
        onChildAdded(agentIdQuery, (snapshot) => {
            const data = snapshot.val();
            if (selectedAgent.eight_by_eight_agent_id === data.AgentId) {
                const timeoutId = setTimeout(() => { setLogs(oldLogs => [data, ...oldLogs]); }, 50)
                timeOuts.push(timeoutId)
            }
        });
    }

    const handleGetDataFromSql = async (date, agent, page, size) => {
        setIsLogsFromRealTimeDb(false)
        let timeZone = moment(new Date(date)).tz("America/Montreal").format("Z")
        let newDate = moment(new Date(date)).format("YYYY MM DD 00:00")
        let offsetSign = timeZone.charAt(0);
        let offsetHours = parseInt(timeZone.substring(1, 3));
        let offsetMinutes = parseInt(timeZone.substring(4));
        let formattedDate
        if (offsetSign == "+") {
            formattedDate = moment(new Date(newDate)).subtract(offsetHours, 'hours').subtract(offsetMinutes, 'minutes').format("YYYY-MM-DD HH:mm")
        } else if (offsetSign == "-") {
            formattedDate = moment(new Date(newDate)).add(offsetHours, 'hours').add(offsetMinutes, 'minutes').format("YYYY-MM-DD HH:mm")
        }
        try {
            if (page <= totalCount) {
                if (page === 0) setIsAgentLogsLoading(true)
                let status = agentState ? agentState : ""
                let data = await getAgentLogs(formattedDate, agent.user_id, status, page, size)
                if (data.logs) {
                    setTimeout(() => { setLogs(oldLogs => [...oldLogs, ...data.logs]); }, 50)
                    setTotalCount(Math.ceil(data.count / 10))
                    setTotalLogs(data.count)
                }
                if (page === 0) setIsAgentLogsLoading(false)
            }
        } catch (error) {
            setLogs([])
            setIsAgentsLoading(false)
        }
    }

    return (
        <Box sx={{ height: "95%" }}>
            <div className="bg-shadow bg-white agent-logs-upper-section">
                <Box sx={{ marginTop: "20px", marginLeft: "10px" }}>
                    <Link to="/wallboard">
                        <IconButton className="icon-btn" style={{ width: "35px", height: "35px", marginRight: "10px" }}>
                            <Tooltip title={"Go Back To Score Dashboard"}><ArrowBackIosNewIcon ></ArrowBackIosNewIcon></Tooltip>
                        </IconButton>
                    </Link>
                </Box>
                {
                    agent && (logsViewOption == LOGS_VIEW_OPTIONS.LIST) ?
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Avatar
                                style={{
                                    height: "55px",
                                    width: "55px",
                                    textTransform: "uppercase",
                                }}
                                src={agent?.img}
                            >
                            </Avatar>
                            <h4 className="name-style">{`${agent?.first_name} ${agent?.last_name}`}</h4>
                        </div>
                        :
                        <></>
                }
                <div style={{ display: "flex", alignItems: "center", marginLeft: "auto", gap: "1em" }}>
                    <Box sx={{ marginLeft: "auto" }}>
                        <IconButton className="monitor-btn" onClick={() => setLogsViewOption(LOGS_VIEW_OPTIONS.TILE)} >
                            <Tooltip title={"Logs Tile View"}><GridViewIcon style={{ height: "25px", width: "25px" }}></GridViewIcon></Tooltip>
                        </IconButton>
                    </Box>
                    <Box sx={{ marginLeft: "1%", marginRight: "1%" }}>
                        <IconButton className="icon-btn" onClick={() => setLogsViewOption(LOGS_VIEW_OPTIONS.LIST)} >
                            <Tooltip title={"Logs List View"}><ViewListIcon style={{ height: "25px", width: "25px" }}></ViewListIcon></Tooltip>
                        </IconButton>
                    </Box>
                    {
                        logsViewOption === LOGS_VIEW_OPTIONS.LIST &&
                        <Autocomplete
                            sx={{ width: "300px", flexGrow: 2 }}
                            size="small"
                            disabled={agents === []}
                            loading={isAgentsLoading}
                            noOptionsText={"No agents"}
                            disableClearable
                            options={agents}
                            getOptionLabel={(agent) => `${agent?.first_name?.trim()}${" "}${agent?.last_name?.trim()}`}
                            getOptionDisabled={(agent) => {
                                return agent?.eight_by_eight_agent_id == "";
                            }}
                            renderOption={renderAgentOption}
                            value={agent ?? null}
                            onChange={(_, agent) => {
                                handleAgentChange(agent)
                            }}
                            renderInput={(params) => <CustomTextField  {...params} label="Agents" />}
                        />
                    }
                    <Autocomplete
                        sx={{ minWidth: "300px", flexGrow: 2, marginRight: "10px" }}
                        size="small"
                        multiple={true}
                        disabled={agentStatus === []}
                        loading={isAgentStatusLoading}
                        options={agentStatus}
                        getOptionLabel={(state) => state}
                        renderOption={renderStateOption}
                        value={agentState ?? []}
                        onChange={(_, state) => {
                            setAgentState(state)
                            setTotalLogs(0)
                        }}
                        renderInput={(params) => <CustomTextField  {...params} label="Agent Status" />}
                    />
                    {
                        logsViewOption === LOGS_VIEW_OPTIONS.LIST &&
                        <div style={{ width: "200px", float: 'right', marginRight: "10px" }}>
                            <ReactDatePicker
                                maxDate={new Date()}
                                selected={date}
                                onChange={handleDateChange}
                                customInput={<CustomTextField
                                    size="small"
                                    InputProps={{
                                        endAdornment:
                                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} >
                                                <EventIcon style={{ color: "gray", cursor: "pointer" }} />
                                            </Box>
                                    }}
                                />}
                                dateFormat="MM/dd/yyyy"
                            />
                        </div>
                    }
                </div>
            </div>
            {
                logsViewOption === LOGS_VIEW_OPTIONS.LIST ?
                    <AgentLogsListView page={page} setPage={setPage} agentState={agentState} date={date} agent={agent} totalLogs={totalLogs} logs={logs} isLogsFromRealTimeDb={isLogsFromRealTimeDb} isAgentLogsLoading={isAgentLogsLoading} ></AgentLogsListView>
                    :
                    <AgentLogsTileView agents={agents} agentState={agentState} ></AgentLogsTileView>
            }
        </Box>
    )
}
