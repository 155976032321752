import { useCallback, useContext, useEffect, useRef, useState } from "react";
import moment from "moment"
import { Typography, IconButton, Tooltip, Dialog, Box, Paper, Grid, CircularProgress, Button, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import FaxIcon from '@mui/icons-material/Fax';
import CallMadeIcon from '@mui/icons-material/CallMade';
import SmallCard from "../../../../../components/servicetitan/SmallCard";
import AppointmentCard from "./AppointmentCard";
import DialogHeader from "../../../../../components/dialog/DialogHeader";
import DialogBody from "../../../../../components/dialog/DialogBody";
import { getCustomerById, getLocationById, getJobTypeById, getCampaignById, getTagTypes, getBusinessUnitById, getACustomerContacts, getEmployeeById, getJobCancelReasons, restoreJob, getJobsCancelReasons, getServiceTitanEmployees, getServiceTitanTechnicians, getOutboundCallDetailsByServiceTitanJobId } from "../../../../../services/serviceTitan"
import { chooseColorForJobStatus, getAddressString, isAbleToNavigateToServiceTitanPage, sendAEmail } from "../../../../../utils/miscellaneous"
import { getPhoneNumberWithInputMask } from "../../../../../utils/inputMasks"
import { handleGetEstimatesByJobId, handleGetappointments } from "../../../../../state-services-dublicate/serviceTitan";
import RefreshErrorView from "../../../../../components/refreshView/RefreshErrorView";
import RefreshButton from "../../../../../components/refreshView/RefreshButton";
import AodIcon from '@mui/icons-material/Aod';
import { CommonContext } from "../../../../../contexts/CommonContextProvider";
import ImageBox from "../../../../../components/LogoBox/ImageBox";
import CancelJobModal from "./CancelJobModal";
import { NOTIFICATION_TYPES } from "../../../../../constants/common";
import LoadingButton from "../../../../../components/LoadingButton";
import EstimateCard from "./EstimateCard";
import ViewJobModalNotesSection from "./ViewJobModalNotesSection";
import { getServiceTitanAvailability } from "../../../../../services/serviceTitan";
import { getServiceTitanAppointmentAssignments } from "../../../../../services/serviceTitan";
import RestoreJobCheckingModal from "./RestoreJobCheckingModal";
import RestoreJobWithNewTimesModal from "../../create-job-page/restoreJobWithNewTimesModal";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function goToServiceTitanJobPage(franchise, jobId) {
    if (jobId && franchise?.service_titan_tenant_name) {
        window.open(`https://go.servicetitan.com/#/proxyJob/Index/${jobId}/${franchise?.service_titan_tenant_name}`, "_blank");
    }
}

function ViewJobModal(props) {
    const { open, onClose, job, setToggleRefresh, toggleRefresh, brand, franchise, showNotification, setIsAddMultipleWorkorders, setAppointmentToReschedule } = props
    const [customer, setCustomer] = useState();
    const [location, setLocation] = useState();
    const [customerContacts, setCustomerContacts] = useState([]);
    const [jobType, setJobType] = useState();
    const [createdByEmployee, setCreatedByEmployee] = useState();
    const [businessUnit, setBusinessUnit] = useState();
    const [campaign, setCampaign] = useState();
    const [tagTypes, setTagTypes] = useState([]);
    const [appointmentCount, setAppointmentCount] = useState(job?.appointmentCount);
    const [jobCancelReasons, setJobCancelReasons] = useState([])
    const [currentJobCancelReason, setCurrentJobCancelReason] = useState(null)
    const [isOpenCancelJobsModal, setIsOpenCancelJobsModal] = useState(false)
    const [isRestoreJobLoading, setIsRestoreJobLoading] = useState(false)
    const [toggleRefreshAppointments, setToggleRefreshAppointments] = useState(false);
    const [toggleRefreshEstimates, setToggleRefreshEstimates] = useState(false)
    const [appointments, setAppointments] = useState([])
    const [isOpenRestoreJobCheckingModal, setIsOpenRestoreJobCheckingModal] = useState(false)
    const [isOpenAppointmentRescheduleModal, setisOpenAppointmentRescheduleModal] = useState(false)
    const status = useRef(job?.jobStatus)
    const restoreJobLoadingMessage = useRef("")
    const { popPhoneDialer } = useContext(CommonContext)

    useEffect(() => {
        if (franchise && job) {
            getCustomerById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, job?.customerId,).then((customerResponse) => {
                setCustomer(customerResponse)
            }).catch(() => {
            })

            getLocationById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, job?.locationId,).then((locationResponse) => {
                setLocation(locationResponse)
            }).catch(() => {
            })
            getACustomerContacts(franchise?.id, job?.customerId).then((customerContactResponse) => {
                if (Array.isArray(customerContactResponse.data)) {
                    setCustomerContacts(customerContactResponse.data);
                }
            }).catch(() => {
            })

            getJobCancelReasons(franchise?.id).then((jobCancelReasonsRes) => {
                if (Array.isArray(jobCancelReasonsRes?.data)) {
                    setJobCancelReasons(jobCancelReasonsRes?.data);
                }
            }).catch(() => {
            })

            getJobTypeById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, job?.jobTypeId,).then((jobResponse) => {
                setJobType(jobResponse)
            }).catch(() => {
            })

            getEmployeeById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, job?.createdById).then((employeeResponse) => {
                setCreatedByEmployee(employeeResponse);
            }).catch(() => {
            })

            getBusinessUnitById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, job?.businessUnitId).then((businessUnitResponse) => {
                setBusinessUnit(businessUnitResponse);
            }).catch(() => {
            })

            getCampaignById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, job?.campaignId).then((campaignResponse) => {
                setCampaign(campaignResponse);
            }).catch(() => {
            })
            getTagTypes(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret,).then((tagTypesResponse) => {
                if (Array.isArray(tagTypesResponse.data)) {
                    const releventTages = tagTypesResponse.data.filter((tagType) => job?.tagTypeIds.includes(tagType.id))
                    setTagTypes(releventTages);
                }
            }).catch(() => {
            })

        }
    }, [franchise, franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, job, job?.id])

    useEffect(() => {
        if (status?.current === 'Canceled') {
            let jobIds = [job?.id]
            getJobsCancelReasons(franchise?.id, jobIds).then((jobCancelReasonsRes) => {
                if (Array.isArray(jobCancelReasonsRes?.data)) {
                    setCurrentJobCancelReason(jobCancelReasonsRes?.data[0]);
                }
            }).catch(() => {
            })
        }
        return () => {
            setCurrentJobCancelReason(null)
        }
    }, [franchise?.id, job?.id])

    function handleOpenCancelJob() {
        setIsOpenCancelJobsModal(true)
    }

    function handleCloseCancelJob(canceledJob) {
        if (canceledJob) {
            status.current = canceledJob?.jobStatus
            setToggleRefresh(!toggleRefresh)
            let jobIds = [job?.id]
            getJobsCancelReasons(franchise?.id, jobIds).then((jobCancelReasonsRes) => {
                if (Array.isArray(jobCancelReasonsRes?.data)) {
                    setCurrentJobCancelReason(jobCancelReasonsRes?.data[0]);
                }
            }).catch(() => {
            })
        }
        setIsOpenCancelJobsModal(false)
    }

    async function handlePopPhoneDialer(contact) {
        popPhoneDialer(contact, franchise?.id, brand?.id, job?.id, "ServiceTitanJob")
    }

    function handleOpenTimeSlotModal() {
        setIsOpenRestoreJobCheckingModal(false)
        setisOpenAppointmentRescheduleModal(true)
    }

    function handleCloseRestoreJobCheckingModal() {
        setIsOpenRestoreJobCheckingModal(false)
    }

    function handleClosePreviousAppointmentRescheduleModal(isRescheduled = false) {
        setisOpenAppointmentRescheduleModal(false)
        if (isRescheduled) {
            setToggleRefresh(!toggleRefresh)
            setToggleRefreshAppointments(!toggleRefreshAppointments)
        }
    }

    function handleCloseJobRestoreModalForChecking() {
        setIsOpenRestoreJobCheckingModal(false)
    }

    function handleRestoreMutipleAppointments() {
        jobRestore(franchise, setIsRestoreJobLoading, setToggleRefresh, toggleRefresh, toggleRefreshAppointments, setToggleRefreshAppointments, status, showNotification, job)
    }

    return <>
        <Dialog fullWidth open={open} maxWidth="lg" onClose={() => { }}>
            <Box style={{ height: "10vh" }} className={"dialog-color"}>
                <DialogHeader onClose={() => { onClose(job, appointmentCount) }}>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "0.25em" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", padding: "1em", borderRadius: "0.5em" }}>
                            <Typography variant="h5" component={"h1"} fontWeight="bold" >{`Job #${job?.id}`}</Typography>
                            <Typography variant="subtitle2" component={"p"} margin={"0"} padding={"0"} color={"grayText"} >{`${franchise?.franchise_name}`}</Typography>
                        </Box>
                        <ImageBox alt="brand-logo" src={brand?.brand_img} size={'large'} />
                        <Box flexGrow={2}></Box>
                        <Tooltip title={isAbleToNavigateToServiceTitanPage(franchise) ? "View in ServiceTitan" : ""}>
                            <IconButton
                                className={isAbleToNavigateToServiceTitanPage(franchise) ? "btn-primary" : "btn-disable"}
                                disabled={!isAbleToNavigateToServiceTitanPage(franchise)}
                                data-test='view-in-servicetitan'
                                onClick={(e) => {
                                    e.preventDefault();
                                    goToServiceTitanJobPage(franchise, job.id)
                                }} ><CallMadeIcon /></IconButton>
                        </Tooltip>
                    </Box>
                </DialogHeader>
            </Box>
            <Box minHeight={"80vh"} maxHeight={"80vh"} style={{ overflowY: "scroll" }} className={"dialog-color"}>
                <DialogBody>
                    <Paper sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", gap: "0.5em", padding: "0.25em", justifyContent: "space-evenly" }}>
                        <SmallCard heading="Received" value={job?.createdOn ? moment(new Date(job?.createdOn))?.format('MM/DD/YYYY h:mm A') : "-"} />
                        <SmallCard heading="Job Type" value={jobType?.name ?? "-"} />
                        <SmallCard heading="Priority" value={job?.priority ?? "-"} />
                        <SmallCard heading="Business Unit" value={businessUnit?.name ?? "-"} />
                        <SmallCard heading="Status" value={status?.current ?? "-"} shouldAddColorTovalue={true} colorOfTheValue={chooseColorForJobStatus(status?.current ?? "-")} />
                        <SmallCard heading="Campaign" value={campaign?.name ?? "-"} />
                        <SmallCard heading="Created By" value={createdByEmployee?.name ?? "-"} />
                    </Paper>
                    <Box sx={{ height: "280px", display: "flex", flexDirection: "row", alignItems: "stretch", gap: "0.5em", borderRadius: "0.5em", marginBlock: "1em" }}>
                        <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", width: "30%", }}>
                            <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Tags</Typography>
                            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "0.5em", }}>
                                {tagTypes?.map((tagType, i) => {
                                    return <Typography key={i} style={{ paddingBlock: "0.1em", paddingInline: "0.5em", margin: "0", borderRadius: "0.5em" }} sx={{ backgroundColor: 'background.icon' }}>{tagType.name}</Typography>
                                })}
                            </Box>
                        </Box>
                        <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em", flexGrow: "1", width: "70%" }}>
                            <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Overview</Typography>
                            <Box sx={{ display: "flex", flexDirection: "column", }}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={6}>
                                        <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                            <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Location</Typography>
                                            <Typography style={{ margin: "0" }}>{location?.name ?? ""}</Typography>
                                            <Typography style={{ margin: "0" }}>{getAddressString(location?.address)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                            <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                                                <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer</Typography>
                                                <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>{`(${customer?.type ?? ""})`}</Typography>
                                            </Box>
                                            <Typography style={{ margin: "0" }}>{customer?.name ?? ""}</Typography>
                                            <Typography style={{ margin: "0" }}>{getAddressString(customer?.address)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                            {customerContacts?.some((contact) => contact.type === "Phone") ? <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer Phone</Typography> : <></>}
                                            {customerContacts.map((contact, i) => {
                                                return (contact.type === "Phone") ?
                                                    <Box key={i} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <Tooltip title="Call" >
                                                            <IconButton sx={{ color: "#00a79d" }} aria-label="Call" component="span" onClick={() => {
                                                                handlePopPhoneDialer(contact.value)
                                                            }}>
                                                                <CallIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Typography style={{ margin: "0" }}>{getPhoneNumberWithInputMask(contact.value)}</Typography>
                                                    </Box>
                                                    : <></>
                                            })}
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                            {customerContacts?.some((contact) => contact.type === "MobilePhone") ? <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer Mobile Phone</Typography> : <></>}
                                            {customerContacts.map((contact, i) => {
                                                return (contact.type === "MobilePhone") ?
                                                    <Box key={i} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <Tooltip title="Call" >
                                                            <IconButton sx={{ color: "#00a79d" }} aria-label="Call" component="span" onClick={() => {
                                                                handlePopPhoneDialer(contact.value)
                                                            }}>
                                                                <AodIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Typography style={{ margin: "0" }}>{getPhoneNumberWithInputMask(contact.value)}</Typography>
                                                    </Box>
                                                    : <></>
                                            })}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                            {customerContacts?.some((contact) => contact.type === "Email") ? <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer Email</Typography> : <></>}
                                            {customerContacts.map((contact, i) => {
                                                return (contact.type === "Email") ? <Box key={i} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Tooltip title="Email" >
                                                        <IconButton sx={{ color: "#00a79d" }} aria-label="Email" component="span" onClick={() => {
                                                            sendAEmail(contact.value)
                                                        }}>
                                                            <EmailIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Typography style={{ margin: "0" }}>{contact.value}</Typography>
                                                </Box> : <></>
                                            })}
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden", marginTop: "1em" }}>
                                            {customerContacts?.some((contact) => contact.type === "Fax") ? <Typography style={{ fontSize: "0.9em", color: "GrayText", margin: "0", marginBottom: "0.5em" }}>Customer Fax</Typography> : <></>}
                                            {customerContacts.map((contact, i) => {
                                                return (contact.type === "Fax") ? <Box key={i} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Tooltip title="Fax" >
                                                        <FaxIcon sx={{ margin: "0.4em" }} />
                                                    </Tooltip>
                                                    <Typography style={{ margin: "0" }}>{getPhoneNumberWithInputMask(contact.value)}</Typography>
                                                </Box> : <></>
                                            })}
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", marginTop: "1em" }}>
                                    <Typography style={{ fontSize: "1.1em", fontWeight: "bold", margin: "0", marginBottom: "0.3em" }}>Summary</Typography>
                                    <Box sx={{ inlineSize: '100%', overflowX: 'auto', overflowWrap: 'break-word' }}>
                                        <Typography >{job?.summary}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", marginTop: "1em" }}>
                                    <Typography style={{ fontSize: "1.1em", fontWeight: "bold", margin: "0", marginBottom: "0.3em" }}>Follow-up Call History</Typography>
                                    <Box sx={{ inlineSize: '100%', overflowX: 'auto', overflowWrap: 'break-word' }}>
                                        <CallHistory jobId={job?.id} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {(status?.current !== 'Canceled') &&
                        <Box sx={{ display: "flex", flexDirection: "column", padding: '10px', width: "100%" }}>
                            <Button size={"small"} variant="contained" style={{ marginTop: "0.5em", marginLeft: "auto" }} onClick={() => handleOpenCancelJob()} disabled={isOpenCancelJobsModal} className={isOpenCancelJobsModal ? "btn-disable" : "btn-primary"} >Cancel Job</Button>
                        </Box>
                    }
                    {(status?.current === 'Canceled') &&
                        <Box sx={{ display: 'flex', flexDirection: "column", gap: "0.2em" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", padding: '10px', width: "100%" }}>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center', gap: '1em', width: "90%" }}>
                                    <Typography style={{ fontSize: "1.1em", fontWeight: "bold" }}>Cancel Reason </Typography>
                                    <Typography >({currentJobCancelReason?.name}) : {currentJobCancelReason?.text}</Typography>
                                </Box>
                                <LoadingButton type={"submit"} size={"small"} disabled={isRestoreJobLoading} variant="contained" loading={isRestoreJobLoading} style={{ marginLeft: "auto", height: "30px" }} className={!isRestoreJobLoading ? "btn-primary" : "btn-disable"}
                                    onClick={async () => {
                                        if (appointments.length <= 1) {
                                            let canBeRestored = await checkIfJobCanBeRestored(franchise, brand, appointments[0], setIsRestoreJobLoading, showNotification)
                                            if (!canBeRestored) {
                                                restoreJobLoadingMessage.current = "Job cannot be restored because the time slot is not available. Do you like to book a new time slot?"
                                                setIsRestoreJobLoading(false)
                                                setIsOpenRestoreJobCheckingModal(true)
                                                return
                                            }else{
                                                jobRestore(franchise, setIsRestoreJobLoading, setToggleRefresh, toggleRefresh, toggleRefreshAppointments, setToggleRefreshAppointments, status, showNotification, job)
                                            }
                                        } else {
                                            restoreJobLoadingMessage.current = "When restoring a Job with multiple appointments, time slots availability will not be considered. Are you still like to restore the job ?"
                                            setIsOpenRestoreJobCheckingModal(true)
                                        }
                                    }}
                                >
                                    Restore Job
                                </LoadingButton>
                            </Box>
                            <Box sx={{marginLeft:"10px", display:"flex", flexDirection:"row", alignItems:"center", gap:"0.6em"}}>
                                    <InfoOutlinedIcon sx={{ cursor: "pointer" }} color="info" style={{ height: "30px", width: "30px" }} />
                                    <p style={{color:"#3598DB"}}>{"Cancelling a job releases its time slots. Restoring the job will reuse the original slot if available otherwise, you must reschedule to restore the job."}</p>
                            </Box>
                        </Box>
                    }
                    {isOpenCancelJobsModal ?
                        <CancelJobModal franchise={franchise} job={job} showNotification={showNotification} cancelReasons={jobCancelReasons} handleCloseCancelJob={handleCloseCancelJob} />
                        :
                        <>
                            <ScheduleSection franchise={franchise} job={job} setAppointmentCount={setAppointmentCount} toggleRefreshAppointments={toggleRefreshAppointments} setToggleRefreshAppointments={setToggleRefreshAppointments} setIsAddMultipleWorkorders={setIsAddMultipleWorkorders} setAppointmentToReschedule={setAppointmentToReschedule} appointments={appointments} setAppointments={setAppointments} />
                            <EstimateSection franchise={franchise} brand={brand} job={job} toggleRefreshEstimates={toggleRefreshEstimates} setToggleRefreshEstimates={setToggleRefreshEstimates} />
                            <ViewJobModalNotesSection franchiseId={franchise.id} customerId={job.customerId} locationId={job.locationId} jobId={job.id} showNotification={showNotification} jobRestore={jobRestore} />
                        </>
                    }
                </DialogBody>
            </Box>
        </Dialog>
        {(isOpenRestoreJobCheckingModal && appointments?.length > 1) && <RestoreJobCheckingModal isOpen={isOpenRestoreJobCheckingModal} loadingMessage={restoreJobLoadingMessage?.current} onCloseHandler={handleCloseJobRestoreModalForChecking} handleDialogOpen={handleRestoreMutipleAppointments} />}
        {(isOpenRestoreJobCheckingModal && appointments?.length === 1) && <RestoreJobCheckingModal isOpen={isOpenRestoreJobCheckingModal} loadingMessage={restoreJobLoadingMessage?.current} onCloseHandler={handleCloseRestoreJobCheckingModal} handleDialogOpen={handleOpenTimeSlotModal} />}
        {isOpenAppointmentRescheduleModal ? <RestoreJobWithNewTimesModal
            franchise={franchise} brand={brand}
            showNotification={showNotification}
            isOpen={isOpenAppointmentRescheduleModal}
            onCloseHandler={handleClosePreviousAppointmentRescheduleModal}
            toggleRefresh={toggleRefresh}
            toggleRefreshAppointments={toggleRefreshAppointments}
            status={status}
            jobRestore = {jobRestore}
            appointmentToReschedule={appointments[0]}
            job={job} />
            :
            <></>}
    </>

}
export default ViewJobModal;

function ScheduleSection({ franchise, job, setAppointmentCount, setAppointmentToReschedule, toggleRefreshAppointments, setToggleRefreshAppointments, appointments, setAppointments }) {

    const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(false)
    const [isAppointmentsLoadingError, setIsAppointmentLoadingError] = useState(false)

    useEffect(() => {
        if (franchise && job?.id) {
            handleGetappointments(franchise, job?.id, "", setAppointments, setIsAppointmentsLoading, setIsAppointmentLoadingError, setAppointmentCount)
        }
    }, [franchise, job?.id, toggleRefreshAppointments, setAppointmentCount])

    return <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em" }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", justifyItems: "center" }}>
                <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Scheduled Appointments</Typography>
                {<RefreshButton onRefresh={() => setToggleRefreshAppointments(!toggleRefreshAppointments)} />}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "0.25em", gap: "1em", minHeight: "5em" }}>
                {isAppointmentsLoading ? <CircularProgress size={30} color="inherit" /> : isAppointmentsLoadingError ? <RefreshErrorView onRefresh={() => setToggleRefreshAppointments(!toggleRefreshAppointments)} /> : appointments?.map((appointment, index) => {
                    return <AppointmentCard key={appointment?.id ?? index} index={index} total={appointments?.length ?? 0} appointment={appointment} franchise={franchise} isResheduleFromViewJob={true} setAppointmentToReschedule={setAppointmentToReschedule} shouldAllowCancelAndReschedule={true} />;
                })}
            </Box>
        </Box>

    </>;
}

function EstimateSection({ franchise, brand, job, toggleRefreshEstimates, setToggleRefreshEstimates }) {

    const [estimates, setEstimates] = useState([])
    const [isEstimatesLoading, setIsEstimatesLoading] = useState(false)
    const [isEstimatesLoadingError, setIsEstimatesLoadingError] = useState(false)
    const [serviceTitanEmployees, setServiceTitanEmployees] = useState([])

    const handleGetServiceTitanEmployees = useCallback(
        async () => {
            let resEmployees = await getServiceTitanEmployees(franchise?.id)
            let resTechnicians = await getServiceTitanTechnicians(franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, [])
            let res = [...resEmployees?.data, ...resTechnicians?.data]
            if (Array.isArray(res)) {
                setServiceTitanEmployees(res)
            }
        },
        [franchise?.id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, franchise?.service_titan_tenant_id],
    )

    useEffect(() => {
        handleGetServiceTitanEmployees()
        return () => {
            setServiceTitanEmployees([])
        }
    }, [handleGetServiceTitanEmployees])

    useEffect(() => {
        if (franchise && job?.id) {
            handleGetEstimatesByJobId(franchise, job?.id, setEstimates, setIsEstimatesLoading, setIsEstimatesLoadingError)
        }
        return () => {
            setEstimates([])
            setIsEstimatesLoading(false)
            setIsEstimatesLoadingError(false)
        }
    }, [franchise, job?.id, toggleRefreshEstimates])

    if (!estimates || estimates.length === 0) {
        return null;
    }

    return <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em" }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", justifyItems: "center" }}>
                <Typography style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Estimates</Typography>
                {<RefreshButton onRefresh={() => setToggleRefreshEstimates(!toggleRefreshEstimates)} />}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "0.25em", gap: "1em", minHeight: "5em" }}>
                {isEstimatesLoading ? <CircularProgress size={30} color="inherit" /> : isEstimatesLoadingError ? <RefreshErrorView onRefresh={() => setToggleRefreshEstimates(!toggleRefreshEstimates)} /> : estimates?.map((estimate, index) => {
                    return <EstimateCard key={estimate?.id ?? index} index={index} total={estimates?.length ?? 0} estimate={estimate} franchise={franchise} brand={brand} serviceTitanEmployees={serviceTitanEmployees} />;
                })}
            </Box>
        </Box>
    </>
}

async function checkIfJobCanBeRestored(franchise, brand, appointment, setIsRestoreJobLoading, showNotification) {
    let startDate = moment(appointment?.start).startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
    let endDate = moment(appointment?.start).endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
    let technicianShiftStartDate = moment(appointment?.start).startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]")
    let duration = moment(appointment?.end).diff(moment(appointment?.start), 'minutes') + "m"
    try {
        setIsRestoreJobLoading(true)
        let assignmentRes = await getServiceTitanAppointmentAssignments(franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, "", [appointment?.id])
        let timeSlotRes = await getServiceTitanAvailability(franchise?.id, brand?.brand_id, brand?.brand_short_code, startDate, endDate, duration, technicianShiftStartDate)
        let isAvailable = checkIfTimeSlotAndTechnicianIsAvailable(appointment?.start, timeSlotRes?.available_time_slots, assignmentRes?.data)
        return isAvailable
    } catch (error) {
        showNotification({ message: "Important: Job couldn’t be restored at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
        setIsRestoreJobLoading(false)
    }
}

function checkIfTimeSlotAndTechnicianIsAvailable(currentAppointmentStartTime, availableTimeSlots, assignmentsOfCurrentAppointment) {
    let isAvailable = false
    let technitianIds = assignmentsOfCurrentAppointment?.map((assignment) => assignment?.technicianId)
    let corresspondingTimeSlot = availableTimeSlots.find((timeSlot) => moment(timeSlot.start).isSame(moment(currentAppointmentStartTime)))
    if (corresspondingTimeSlot) {
        let availableTechniciansIds = corresspondingTimeSlot?.available_technician_ids
        if (technitianIds.every((technitianId) => availableTechniciansIds.includes(technitianId))) {
            isAvailable = true
        } else {
            isAvailable = false
        }
    } else {
        isAvailable = false
    }
    return isAvailable
}

async function jobRestore(franchise, setIsRestoreJobLoading, setToggleRefresh, toggleRefresh, toggleRefreshAppointments, setToggleRefreshAppointments, status, showNotification, job) {
    try {
        setIsRestoreJobLoading(true)
        await restoreJob(franchise?.id, job?.id)
        showNotification({ message: "Job has been restored successfully", type: NOTIFICATION_TYPES.SUCCESS, isOpen: true })
        setTimeout(() => {
            status.current = "Scheduled"
            setToggleRefreshAppointments(!toggleRefreshAppointments)
            setToggleRefresh(!toggleRefresh)
        }, 1000)
    } catch (error) {
        showNotification({ message: "Important: Job couldn’t be restored at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsRestoreJobLoading(false)
    }
}

function CallHistory({ jobId }) {

    const [isOutboundCallLoading, setIsOutboundCallLoading] = useState(true)
    const [outboundCalls, setOutboundCalls] = useState([])

    useEffect(() => {
        if (jobId) {
            getOutboundCallDetailsByServiceTitanJobId(jobId).then((response) => {
                setIsOutboundCallLoading(false)
                setOutboundCalls(response)
            }).catch((error) => { })
        }
    }, [])

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25em", padding: "0.25em" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "0.25em", gap: "1em", minHeight: "5em" }}>
                    {isOutboundCallLoading ? <CircularProgress size={30} color="inherit" /> : (
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>From Phone</TableCell>
                                        <TableCell>To Phone</TableCell>
                                        <TableCell>Created At</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {outboundCalls && outboundCalls.length !== 0 ? (
                                        outboundCalls?.map((call, i) => (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell>{call?.from_phone}</TableCell>
                                                <TableCell>{call?.to_phone}</TableCell>
                                                <TableCell>{moment(call?.created_at).format("MM/ DD/ YYYY , h:mm:ss A")}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (<TableRow>
                                        <TableCell style={{ border: "none" }} align={"center"} colSpan={5}>
                                            <Typography style={{ margin: "0" }}>No Follow-up Call History</Typography>
                                        </TableCell>
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                </Box>
            </Box>


        </>
    )
}